.wrapper {
    position: relative;

    .container {
        margin: 0 -24px;
        max-width: 986px;
        color: var(--uui-text-primary);
    }
}

.title-row {
    width: fit-content;
    margin-top: 24px;

    .title {
        font-family: var(--uui-font);
        font-weight: 600;
        color: var(--uui-text-primary);
        font-size: 24px;
        margin-bottom: 6px;
        margin-top: 0;
    }

    .anchor {
        fill: transparent;

        &:focus {
            fill: var(--uui-link);
        }
    }

    &:hover {
        .anchor {
            fill: var(--uui-link-hover);
        }
    }
}
