@use '~@epam/promo/assets/styles/colors' as *;

.icon-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    border-right: 1px solid var(--uui-divider);
}
