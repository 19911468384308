/* Main Colors */
$white: #FFFFFF;

$blue-soft: #F5FDFF;
$blue-lightest: #D6F2FF;
$blue-light: #99DDFF;
$blue: #007BBD;
$blue-dark: #006AA3;
$blue-darkest: #005685;

$green-soft: #FCFFF5;
$green-lightest: #EEFFCC;
$green-light: #CFF486;
$green: #65A300;
$green-dark: #528500;
$green-darkest: #446600;

$amber-soft: #FFFCF5;
$amber-lightest: #FFF2CC;
$amber-light: #FFE38F;
$amber: #FFC000;
$amber-dark: #DB9D00;
$amber-darkest: #9F6B04;

$red-soft: #FEF7F6;
$red-lightest: #FADED9;
$red-light: #F5B5A8;
$red: #DB3A1A;
$red-dark: #C03316;
$red-darkest: #A02B13;

//Additional Colors
$yellow-5: #FFFFF5;
$yellow-10: #FFFFE5;
$yellow-20: #FFFCA4;
$yellow-30: #FDD63B;
$yellow-40: #F9B71D;
$yellow-50: #BC7E02;

$orange-5: #FEF8F4;
$orange-10: #FFE8D7;
$orange-20: #FFCCA7;
$orange-30: #FF8B3E;
$orange-40: #F76B0D;
$orange-50: #BD4B00;

$fuchia-5: #FFF7FB;
$fuchia-10: #F9D8E7;
$fuchia-20: #EDADC8;
$fuchia-30: #EA4386;
$fuchia-40: #D61E68;
$fuchia-50: #AE1955;

$purple-5: #FDF6FE;
$purple-10: #F2CCFA;
$purple-20: #E79DF5;
$purple-30: #B114D1;
$purple-40: #860F9E;
$purple-50: #5E0B6F;

$violet-5: #F8F6FE;
$violet-10: #DBCCFA;
$violet-20: #BB9DF5;
$violet-30: #773CEC;
$violet-40: #5514D6;
$violet-50: #40109E;

$cobalt-5: #F8FAFF;
$cobalt-10: #D9E2FC;
$cobalt-20: #AEC0F5;
$cobalt-30: #0F98FF;
$cobalt-40: #006FE5;
$cobalt-50: #0954A5;

$cyan-5: #F6FEFE;
$cyan-10: #D1FAFA;
$cyan-20: #AAEEEE;
$cyan-30: #14CCCC;
$cyan-40: #0F9E9E;
$cyan-50: #0B6F6F;

$mint-5: #F2FCF5;
$mint-10: #DDF3E4;
$mint-20: #B4DFC4;
$mint-30: #4FC48C;
$mint-40: #2E9E68;
$mint-50: #236E4A;

/* Gray */
$gray5: #FAFAFC;
$gray10: #F5F6FA;
$gray20: #EBEDF5;
$gray30: #E1E3EB;
$gray40: #CED0DB;
$gray50: #ACAFBF;
$gray60: #6C6F80;
$gray70: #474A59;
$gray80: #303240;
$gray90: #1D1E26;

