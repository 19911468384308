@use '~@epam/promo/assets/styles/colors' as *;
@use '~@epam/promo/assets/styles/fonts' as *;

.wrapper {
    overflow: hidden;
    display: flex;
    position: relative;
}

body {
    &:global(.full-screen) {
        .container {
            height: 100vh;
        }
    }
}

.container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 96px);
    transition: all 500ms;
    flex-basis: auto;
    min-width: 0;
    flex-grow: 1;

    :global(.uui-table-row) {
        .detailed-icon {
            visibility: hidden;
        }

        &:hover {
            .detailed-icon {
                visibility: visible;
            }
        }
    }
}

.icon-container {
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    border-right: 1px solid var(--uui-divider-light);
}

.icon {
    &:global(.uui-button-box) {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }
}

.presets {
    padding-left: 50px;
    transition: 0.5s padding-left;
    background-color: var(--uui-surface-main);
    height: 48px;

    &-with-filter {
        padding-left: 0;
    }
}
