// This file is AUTOGENERATED from Figma. DO NOT EDIT THIS FILE DIRECTLY.
@use './_color_classes.scss' as colorClasses;

@mixin theme-tokens {

    /***************/
    /*** Palette ***/
    /***************/

    // ""
    --black: #000000;
    --white: #FFFFFF;

    // "palette-additional/cobalt"
    --cobalt-5: #F8FAFF;
    --cobalt-10: #D9E2FC;
    --cobalt-20: #AEC0F5;
    --cobalt-50: #0F98FF;
    --cobalt-60: #006FE5;
    --cobalt-70: #0954A5;

    // "palette-additional/cyan"
    --cyan-5: #F6FEFE;
    --cyan-10: #D1FAFA;
    --cyan-20: #AAEEEE;
    --cyan-50: #14CCCC;
    --cyan-60: #12B5B5;
    --cyan-70: #0B6F6F;

    // "palette/electric"
    --electric-5: #F3F8FE;
    --electric-10: #CEE1FC;
    --electric-20: #85B4F8;
    --electric-50: #0A68F1;
    --electric-60: #095ED9;
    --electric-70: #0853C1;

    // "palette/emerald"
    --emerald-5: #F3FBF7;
    --emerald-10: #CDEEDD;
    --emerald-20: #97D9B7;
    --emerald-50: #07A956;
    --emerald-60: #06984D;
    --emerald-70: #068745;

    // "palette/fire"
    --fire-5: #FEF6F6;
    --fire-10: #FDE1E1;
    --fire-20: #FBB6B6;
    --fire-50: #FF4242;
    --fire-60: #E22A2A;
    --fire-70: #AD0000;

    // "palette-additional/fuchsia"
    --fuchsia-5: #FFF7FB;
    --fuchsia-10: #F9D8E7;
    --fuchsia-20: #EDADC8;
    --fuchsia-50: #EA4386;
    --fuchsia-60: #D61E68;
    --fuchsia-70: #AE1955;

    // "palette-additional/mint"
    --mint-5: #F2FCF5;
    --mint-10: #DDF3E4;
    --mint-20: #B4DFC4;
    --mint-50: #4FC48C;
    --mint-60: #31AA70;
    --mint-70: #236E4A;

    // "palette/night"
    --night50: #FAFAFC;
    --night100: #F5F6FA;
    --night200: #EBEDF5;
    --night300: #E1E3EB;
    --night400: #CED0DB;
    --night500: #ACAFBF;
    --night600: #6C6F80;
    --night700: #474A59;
    --night800: #303240;
    --night900: #1D1E26;
    --night950: #111111;

    // "palette-additional/orange"
    --orange-5: #FEF8F4;
    --orange-10: #FFE8D7;
    --orange-20: #FFCCA7;
    --orange-50: #FF8B3E;
    --orange-60: #F76B0D;
    --orange-70: #BD4B00;

    // "palette-additional/purple"
    --purple-5: #FDF6FE;
    --purple-10: #F2CCFA;
    --purple-20: #E79DF5;
    --purple-50: #B114D1;
    --purple-60: #860F9E;
    --purple-70: #5E0B6F;

    // "palette/sun"
    --sun-5: #FFFCF5;
    --sun-10: #FFEDC9;
    --sun-20: #FFD785;
    --sun-50: #FCAA00;
    --sun-60: #FF9000;
    --sun-70: #BD5800;

    // "palette-additional/violet"
    --violet-5: #F8F6FE;
    --violet-10: #DBCCFA;
    --violet-20: #BB9DF5;
    --violet-50: #773CEC;
    --violet-60: #5514D6;
    --violet-70: #40109E;

    // "palette-additional/yellow"
    --yellow-5: #FFFFF5;
    --yellow-10: #FFFFE5;
    --yellow-20: #FFFCA4;
    --yellow-50: #FDD63B;
    --yellow-60: #EDBE02;
    --yellow-70: #BC7E02;

    /*********************/
    /*** Core Semantic ***/
    /*********************/

    // "core/semantic/accent-"
    --uui-accent-5: var(--emerald-5);
    --uui-accent-10: var(--emerald-10);
    --uui-accent-20: var(--emerald-20);
    --uui-accent-50: var(--emerald-50);
    --uui-accent-60: var(--emerald-60);
    --uui-accent-70: var(--emerald-70);
    --uui-accent-contrast: var(--white);

    // "core/semantic/critical-"
    --uui-critical-5: var(--fire-5);
    --uui-critical-10: var(--fire-10);
    --uui-critical-20: var(--fire-20);
    --uui-critical-50: var(--fire-50);
    --uui-critical-60: var(--fire-60);
    --uui-critical-70: var(--fire-70);
    --uui-critical-contrast: var(--white);

    // "core/semantic/error-"
    --uui-error-5: var(--fire-5);
    --uui-error-10: var(--fire-10);
    --uui-error-20: var(--fire-20);
    --uui-error-50: var(--fire-50);
    --uui-error-60: var(--fire-60);
    --uui-error-70: var(--fire-70);
    --uui-error-contrast: var(--white);

    // "core/semantic/info-"
    --uui-info-5: var(--electric-5);
    --uui-info-10: var(--electric-10);
    --uui-info-20: var(--electric-20);
    --uui-info-50: var(--electric-50);
    --uui-info-60: var(--electric-60);
    --uui-info-70: var(--electric-70);
    --uui-info-contrast: var(--white);

    // "core/semantic/primary-"
    --uui-primary-5: var(--electric-5);
    --uui-primary-10: var(--electric-10);
    --uui-primary-20: var(--electric-20);
    --uui-primary-50: var(--electric-50);
    --uui-primary-60: var(--electric-60);
    --uui-primary-70: var(--electric-70);
    --uui-primary-contrast: var(--white);

    // "core/semantic/secondary-"
    --uui-secondary-5: var(--night100);
    --uui-secondary-10: var(--night200);
    --uui-secondary-20: var(--night300);
    --uui-secondary-50: var(--night600);
    --uui-secondary-60: var(--night700);
    --uui-secondary-70: var(--night800);
    --uui-secondary-contrast: var(--white);

    // "core/semantic/success-"
    --uui-success-5: var(--emerald-5);
    --uui-success-10: var(--emerald-10);
    --uui-success-20: var(--emerald-20);
    --uui-success-50: var(--emerald-50);
    --uui-success-60: var(--emerald-60);
    --uui-success-70: var(--emerald-70);
    --uui-success-contrast: var(--white);

    // "core/semantic/warning-"
    --uui-warning-5: var(--sun-5);
    --uui-warning-10: var(--sun-10);
    --uui-warning-20: var(--sun-20);
    --uui-warning-50: var(--sun-50);
    --uui-warning-60: var(--sun-60);
    --uui-warning-70: var(--sun-70);
    --uui-warning-contrast: var(--uui-neutral-80);

    /********************/
    /*** Core Neutral ***/
    /********************/

    --uui-neutral-0: var(--white);
    --uui-neutral-5: var(--night50);
    --uui-neutral-10: var(--night100);
    --uui-neutral-20: var(--night200);
    --uui-neutral-30: var(--night300);
    --uui-neutral-40: var(--night400);
    --uui-neutral-50: var(--night500);
    --uui-neutral-60: var(--night600);
    --uui-neutral-70: var(--night700);
    --uui-neutral-80: var(--night800);
    --uui-neutral-90: var(--night900);
    --uui-neutral-95: var(--night950);
    --uui-neutral-100: var(--black);

    /************/
    /*** Core ***/
    /************/

    // "core/surfaces"
    --uui-app-bg: var(--uui-neutral-10);
    --uui-divider: var(--uui-neutral-40);
    --uui-divider-light: var(--uui-neutral-30);
    --uui-overlay: #1D1E26CC;
    --uui-surface-higher: var(--uui-neutral-10);
    --uui-surface-highest: var(--uui-neutral-20);
    --uui-surface-main: var(--uui-neutral-0);
    --uui-surface-sunken: var(--uui-neutral-20);

    // "core/controls"
    --uui-control-bg: var(--uui-surface-main);
    --uui-control-bg-disabled: var(--uui-neutral-5);
    --uui-control-bg-hover: var(--uui-neutral-60);
    --uui-control-border: var(--uui-neutral-40);
    --uui-control-border-disabled: var(--uui-neutral-30);
    --uui-control-border-focus: var(--uui-primary-50);
    --uui-control-border-hover: var(--uui-neutral-60);
    --uui-control-icon: var(--uui-neutral-50);
    --uui-control-icon-disabled: var(--uui-neutral-50);
    --uui-control-placeholder: var(--uui-neutral-50);
    --uui-control-placeholder-disabled: var(--uui-text-disabled);
    --uui-control-text: var(--uui-neutral-95);
    --uui-control-text-disabled: var(--uui-text-tertiary);

    // "core/icons"
    --uui-icon: var(--uui-neutral-95);
    --uui-icon-active: var(--uui-neutral-100);
    --uui-icon-disabled: var(--uui-neutral-50);
    --uui-icon-hover: var(--uui-neutral-100);

    // "core/links"
    --uui-link: var(--uui-primary-50);
    --uui-link-hover: var(--uui-primary-60);
    --uui-link-visited: var(--purple-60);
    --uui-link-visited-hover: var(--purple-70);

    // "core/other"
    --uui-outline-focus: #0A68F17F;
    --uui-skeleton: var(--uui-neutral-40);

    // "core/text"
    --uui-text-bg-highlight: var(--uui-warning-10);
    --uui-text-critical: var(--uui-critical-70);
    --uui-text-disabled: var(--uui-neutral-50);
    --uui-text-info: var(--uui-info-70);
    --uui-text-primary: var(--uui-neutral-95);
    --uui-text-secondary: var(--uui-neutral-70);
    --uui-text-success: var(--uui-success-70);
    --uui-text-tertiary: var(--uui-neutral-60);
    --uui-text-warning: var(--uui-warning-70);

    @include colorClasses.theme-color-classes;
}
