.root {
  display: flex;
  padding: 4px;
}
.root:not(.preview) {
  margin: 24px;
  padding: 12px;
}
.root.preview {
  height: 100%;
}