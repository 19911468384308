@use '~@epam/promo/assets/styles/colors.scss' as *;

.container {
    display: flex;

    &:global(.uui-flex-row)[class*='uui-size-'] {
        --uui-size: 36px;
    }

    &[class*='uui-size-'] {
        --uui-size: 36px;
    }

    .item {
        padding-left: 18px;
        padding-right: 18px;
        color: var(--uui-neutral-0) !important;

        &:global(.uui-button-box) {
            --uui-size: 36px;
        }

        &:hover {
            background-color: var(--uui-neutral-90);
        }

        :global(.uui-icon) {
            fill: var(--uui-neutral-0);
        }
    }

    .divider {
        position: relative;
        margin: 0;
        box-sizing: content-box;
        width: 1px;
        height: 24px;

        &::before {
            content: '';
            position: absolute;
            height: 100%;
            border-right: thin solid var(--uui-icon);
        }
    }
}
