.wrapper {
    padding: 3rem 1rem 0;
}

.target-body-container {
    padding: 0 12px;
    border: 1px solid #CED0DB;
    cursor: pointer;

    & *:not(:last-of-type) {
        margin-right: 6px;
    }

    &.target-body-container--hover {
        background-color: var(--gray20);
    }
}

.drop-container {
    width: 334px;

    &:global(.uui-dropdown-body) {
        min-width: 300px;
        background-color: var(--gray5);
    }

    .header-row {
        box-sizing: border-box;
        padding: 12px 12px 0 12px;
        min-width: 100%;
        justify-content: space-between;

        .header-row-text {
            padding: 0;
        }
    }

    .level-row, .description-row {
        box-sizing: border-box;
        padding: 0 12px 6px;
        min-width: 100%;
        align-self: flex-start;

        .row-text {
            padding: 0;
            margin-right: 6px;
        }
    }

    .description-row {
        .row-text {
            margin-right: 0;
        }
    }

    .icon-buttons-row {
        box-sizing: border-box;
        width: 100%;
        height: 42px;
        align-items: center;
        justify-content: space-around;
        border-top: 1px solid var(--gray30);
        border-bottom: 1px solid var(--gray30);

        .icon-btn {
            flex-grow: 1;
            height: 100%;

            &:hover, &:active, &:focus {
                background-color: var(--gray20);
                border-color: var(--gray20);
            }
        }
    }

    .info-block {
        box-sizing: border-box;
        width: 100%;
        padding: 12px;
        background-color: var(--white);
        border-bottom: 1px solid var(--gray30);

        .info-block-row {
            min-height: auto;

            &:not(:last-of-type) {
                margin-bottom: 6px;
            }

            .info-item {
                padding-top: 0;
                padding-bottom: 0;
            }
        }
    }
}

.tooltip-wrapper {
    &:global(.uui-tooltip-container) {
        :global(.uui-tooltip-body) {
            margin: 0;
            padding: 0;
            background-color: var(--gray80);
        }
    }
}

.tooltip-container {
    box-sizing: border-box;
    width: 100%;

    .tooltip-header {
        background-color: var(--gray90);
        min-height: auto;
        padding: 0 12px;

        .tooltip-header-item {
            padding: 6px 0 6px;
        }
    }

    .tooltip-block-row {
        min-height: auto;
        padding: 0 12px;

        &:first-of-type {
            padding-top: 3px;
        }

        &:last-of-type {
            padding-bottom: 3px;
        }

        .tooltip-item {
            padding: 3px 0 3px;
        }
    }
}
