.rating-wrapper {
    box-sizing: border-box;
    display: inline-grid;
    grid-template-columns: repeat(4, 6px);
    column-gap: 1px;

    .rating-block {
        box-sizing: border-box;
        width: 6px;
        height: 12px;
        border: 1px solid var(--gray40);

        &.active {
            background-color: var(--green-50);
            border: 1px solid var(--green-50);
        }

        &:first-of-type {
            border-radius: 2px 0 0 2px;
        }

        &:last-of-type {
            border-radius: 0 2px 2px 0;
        }
    }
}

.rating-block-alone {
    box-sizing: border-box;
    width: 27px;
    height: 12px;
    border: 1px solid var(--gray40);
    border-radius: 3px;
    text-align: center;

    .block-alone-text {
        padding: 0;
        margin: 0;
        font-family: var(--uui-font);
        font-weight: 600;
        font-size: 9px;
        line-height: 10px;
        color: var(--gray50);
    }

    .block-alone-svg-wrapper {
        align-items: center;
        height: 100%;
    }
}

.icon-gray50 {
    fill: var(--uui-secondary-50);
}
