.root {
    display: inline-block;
    word-break: break-word;

    &.truncate {
        word-break: normal;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 120px;
    }
}
