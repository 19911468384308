:global(.uui-typography):not(:global(.uui-readonly)) a:visited {
  color: var(--uui-link);
}
:global(.uui-typography):not(:global(.uui-readonly)) a:visited:hover {
  color: var(--uui-link-hover);
}

.editor {
  padding: 1px 24px 0;
  margin-top: -1px;
  flex: 1;
}
.editor span[data-slate-placeholder=true] {
  top: initial !important;
}

.container {
  position: relative;
  border-width: 1px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.container :global(.ordered-list),
.container :global(.unordered-list),
.container blockquote {
  margin: 12px 0;
}
.container :global(.ordered-list) ul,
.container :global(.ordered-list) ol,
.container :global(.unordered-list) ul,
.container :global(.unordered-list) ol {
  margin: 3px 0;
}
.container blockquote p {
  margin: 0;
}
.container :focus-visible {
  outline: none;
}

.mode-form {
  background-color: var(--uui-surface-main);
  border-color: var(--uui-control-border);
  border-style: solid;
}
.mode-form:hover {
  border-color: var(--uui-control-border-hover);
}
.mode-form:global(.uui-focus) {
  border-color: var(--uui-primary-50);
}
.mode-form:global(.uui-readonly) {
  border-color: var(--uui-control-border);
}

.mode-inline {
  background-color: transparent;
  border-color: transparent;
  border-style: solid;
}
.mode-inline:hover {
  border-color: var(--uui-control-border-hover);
}
.mode-inline:global(.uui-focus) {
  border-color: var(--uui-primary-50);
}
.mode-inline:global(.uui-readonly) {
  border-color: transparent;
}

.scrollbars {
  height: 100%;
}