.layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.grid {
    transform: scaleY(100500);
}

.timeline {
    position: absolute;
    top: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    user-select: none;
}
