.toolbar-button {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 42px;
  color: var(--uui-neutral-10);
  fill: var(--uui-neutral-10);
  outline: none;
}
.toolbar-button:global(.uui-button-box) {
  border-radius: 0;
}
.toolbar-button :global(.uui-icon) svg {
  height: 42px !important;
  max-width: 42px !important;
}
.toolbar-button:global(.uui-disabled) {
  fill: var(--uui-neutral-60);
  color: var(--uui-neutral-60);
}
.toolbar-button :global(.uui-caption) {
  padding: 0 12px;
  align-self: center;
  font-family: var(--uui-font);
  font-weight: 600;
  font-size: 14px;
}

.gray80 {
  background-color: var(--uui-neutral-80);
}
.gray80:not(.gray80:global(.uui-disabled)):hover {
  background-color: var(--uui-neutral-90);
}
.gray80:not(.gray80:global(.uui-disabled)):active {
  background-color: var(--uui-neutral-90);
}

.gray90 {
  background-color: var(--uui-neutral-90);
}