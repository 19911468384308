@use '~@epam/promo/assets/styles/colors' as *;
@use '~@epam/promo/assets/styles/fonts' as *;

.paginator-wrapper {
    justify-content: flex-end;
    box-shadow: inset 0 1px 0 var(--uui-divider);
    background-color: var(--uui-surface-higher);

    .items-per-page {
        margin-right: 36px;

        :global(.uui-label) {
            font-family: var(--uui-font);
        }

        :global(.uui-input) {
            max-width: 24px;
        }
    }

    .go-to-page {
        :global(.uui-label) {
            font-family: var(--uui-font);
        }

        :global(.uui-input) {
            max-width: 18px;
        }
    }

    .go-to-page-button {
        margin-right: 36px;
        margin-left: 3px;
        width: 24px;
    }
}
