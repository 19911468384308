@use 'tokens/_theme_promo.scss' as tokens;
/*  Name convention  */
/* --uui-'component name or group of components name'-'styled part name'-'states(optional)'-'hover/active/focus(optional)' */

@mixin promo_variables {
    --uui-font: 'Source Sans Pro', sans-serif;
    --uui-font-mono: 'Roboto Mono', monospace;

    // theme specific fonts
    --font-museo-sans: 'Museo Sans', 'Source Sans Pro', Arial, sans-serif;
    --font-museo-slab: 'Museo Slab', 'Roboto Slab', Arial, sans-serif;

    /* shadows */
    --uui-shadow-level-1: 0 0 3px rgba(29, 30, 38, 0.05), 0 3px 6px rgba(29, 30, 38, 0.1);
    --uui-shadow-level-2: 0 6px 12px rgba(29, 30, 38, 0.05), 0 3px 12px rgba(29, 30, 38, 0.1), 0 0 6px rgba(29, 30, 38, 0.05);
    --uui-shadow-level-3: 0 0 10px rgba(29, 30, 38, 0.05), 0 6px 36px rgba(29, 30, 38, 0.12), 0 9px 15px rgba(29, 30, 38, 0.05);

    @include tokens.theme-tokens;

    & {
        --uui-border-radius: 0;
    }

    /* begin color classes */

    .uui-color-green {
        --uui-color-5: var(--uui-accent-5);
        --uui-color-10: var(--uui-accent-10);
        --uui-color-20: var(--uui-accent-20);
        --uui-color-50: var(--uui-accent-50);
        --uui-color-60: var(--uui-accent-60);
        --uui-color-70: var(--uui-accent-70);
        --uui-color-contrast: var(--uui-accent-contrast);
    }

    .uui-color-blue {
        --uui-color-5: var(--uui-primary-5);
        --uui-color-10: var(--uui-primary-10);
        --uui-color-20: var(--uui-primary-20);
        --uui-color-50: var(--uui-primary-50);
        --uui-color-60: var(--uui-primary-60);
        --uui-color-70: var(--uui-primary-70);
        --uui-color-contrast: var(--uui-primary-contrast);
    }

    .uui-color-gray {
        --uui-color-5: var(--uui-secondary-5);
        --uui-color-10: var(--uui-secondary-10);
        --uui-color-20: var(--uui-secondary-20);
        --uui-color-50: var(--uui-secondary-50);
        --uui-color-60: var(--uui-secondary-60);
        --uui-color-70: var(--uui-secondary-70);
        --uui-color-contrast: var(--uui-secondary-contrast);
    }

    .uui-color-red {
        --uui-color-5: var(--uui-critical-5);
        --uui-color-10: var(--uui-critical-10);
        --uui-color-20: var(--uui-critical-20);
        --uui-color-50: var(--uui-critical-50);
        --uui-color-60: var(--uui-critical-60);
        --uui-color-70: var(--uui-critical-70);
        --uui-color-contrast: var(--uui-critical-contrast);
    }

    .uui-color-amber {
        --uui-color-5: var(--uui-warning-5);
        --uui-color-10: var(--uui-warning-10);
        --uui-color-20: var(--uui-warning-20);
        --uui-color-50: var(--uui-warning-50);
        --uui-color-60: var(--uui-warning-60);
        --uui-color-70: var(--uui-warning-70);
        --uui-color-contrast: var(--uui-warning-contrast);
    }

    .uui-link_button.uui-color-contrast,
    .uui-color-gray10 {
        --uui-color-5: var(--white);
        --uui-color-10: var(--white);
        --uui-color-20: var(--gray5);
        --uui-color-50: var(--gray10);
        --uui-color-60: var(--gray20);
        --uui-color-70: var(--gray30);
        --uui-color-contrast: var(--gray70);
    }

    .uui-color-gray30 {
        --uui-color-5: var(--gray20);
        --uui-color-10: var(--gray30);
        --uui-color-20: var(--gray40);
        --uui-color-50: var(--gray30);
        --uui-color-60: var(--gray40);
        --uui-color-70: var(--gray50);
        --uui-color-contrast: var(--gray80);
    }

    .uui-color-gray50 {
        --uui-color-5: var(--gray5);
        --uui-color-10: var(--gray10);
        --uui-color-20: var(--gray20);
        --uui-color-50: var(--gray50);
        --uui-color-60: var(--gray60);
        --uui-color-70: var(--gray70);
        --uui-color-contrast: var(--gray80);
    }

    .uui-color-gray60 {
        --uui-color-5: var(--gray20);
        --uui-color-10: var(--gray60);
        --uui-color-20: var(--gray40);
        --uui-color-50: var(--gray60);
        --uui-color-60: var(--gray70);
        --uui-color-70: var(--gray80);
        --uui-color-contrast: var(--white);
    }

    .uui-color-yellow {
        --uui-color-5: var(--yellow-5);
        --uui-color-10: var(--yellow-10);
        --uui-color-20: var(--yellow-20);
        --uui-color-50: var(--yellow-50);
        --uui-color-60: var(--yellow-60);
        --uui-color-70: var(--yellow-70);
        --uui-color-contrast: var(--gray80);
    }

    .uui-color-orange {
        --uui-color-5: var(--orange-5);
        --uui-color-10: var(--orange-10);
        --uui-color-20: var(--orange-20);
        --uui-color-50: var(--orange-50);
        --uui-color-60: var(--orange-60);
        --uui-color-70: var(--orange-70);
        --uui-color-contrast: var(--gray80);
    }

    .uui-color-fuchsia {
        --uui-color-5: var(--fuchsia-5);
        --uui-color-10: var(--fuchsia-10);
        --uui-color-20: var(--fuchsia-20);
        --uui-color-50: var(--fuchsia-50);
        --uui-color-60: var(--fuchsia-60);
        --uui-color-70: var(--fuchsia-70);
        --uui-color-contrast: var(--white);
    }

    .uui-color-purple {
        --uui-color-5: var(--purple-5);
        --uui-color-10: var(--purple-10);
        --uui-color-20: var(--purple-20);
        --uui-color-50: var(--purple-50);
        --uui-color-60: var(--purple-60);
        --uui-color-70: var(--purple-70);
        --uui-color-contrast: var(--white);
    }

    .uui-color-violet {
        --uui-color-5: var(--violet-5);
        --uui-color-10: var(--violet-10);
        --uui-color-20: var(--violet-20);
        --uui-color-50: var(--violet-50);
        --uui-color-60: var(--violet-60);
        --uui-color-70: var(--violet-70);
        --uui-color-contrast: var(--white);
    }

    .uui-color-cobalt {
        --uui-color-5: var(--cobalt-5);
        --uui-color-10: var(--cobalt-10);
        --uui-color-20: var(--cobalt-20);
        --uui-color-50: var(--cobalt-50);
        --uui-color-60: var(--cobalt-60);
        --uui-color-70: var(--cobalt-70);
        --uui-color-contrast: var(--white);
    }

    .uui-color-cyan {
        --uui-color-5: var(--cyan-5);
        --uui-color-10: var(--cyan-10);
        --uui-color-20: var(--cyan-20);
        --uui-color-50: var(--cyan-50);
        --uui-color-60: var(--cyan-60);
        --uui-color-70: var(--cyan-70);
        --uui-color-contrast: var(--gray80);
    }

    .uui-color-mint {
        --uui-color-5: var(--mint-5);
        --uui-color-10: var(--mint-10);
        --uui-color-20: var(--mint-20);
        --uui-color-50: var(--mint-50);
        --uui-color-60: var(--mint-60);
        --uui-color-70: var(--mint-70);
        --uui-color-contrast: var(--gray80);
    }

    /* Badge */

    .uui-badge.uui-color-white {
        &.uui-fill-solid {
            --uui-badge-bg-hover: var(--uui-neutral-30);
            --uui-badge-border-hover: var(--uui-neutral-30);
        }

        &.uui-fill-outline {
            --uui-badge-bg: var(--uui-neutral-0);
            --uui-badge-bg-hover: var(--uui-neutral-10);
            --uui-badge-border: var(--uui-neutral-30);
            --uui-badge-border-hover: var(--uui-neutral-30);
            --uui-badge-caption: var(--uui-neutral-80);
            --uui-badge-caption-hover: var(--uui-neutral-80);

            .uui-count_indicator {
                --uui-count_indicator-bg: color-mix(in srgb, var(--uui-badge-caption) 8%, transparent);
                --uui-count_indicator-border: color-mix(in srgb, var(--uui-badge-caption) 40%, transparent);
            }
        }
    }

    .uui-badge.uui-color-neutral,
    .uui-badge.uui-color-gray30 {
        &.uui-fill-solid {
            --uui-badge-bg: var(--uui-neutral-30);
            --uui-badge-bg-hover: var(--uui-neutral-40);
            --uui-badge-border: var(--uui-neutral-30);
            --uui-badge-border-hover: var(--uui-neutral-40);
            --uui-color-contrast: var(--uui-neutral-80);

            .uui-count_indicator {
                --uui-count_indicator-bg: var(--uui-neutral-0);
                --uui-count_indicator-border: var(--uui-neutral-0);
            }
        }

        &.uui-fill-outline {
            --uui-badge-bg: var(--uui-neutral-20);
            --uui-badge-border: var(--uui-neutral-40);
            --uui-badge-border-hover: var(--uui-neutral-40);
            --uui-badge-bg-hover: var(--uui-neutral-30);
            --uui-badge-caption: var(--uui-neutral-80);
            --uui-badge-caption-hover: var(--uui-neutral-80);
        }
    }

    .uui-badge.uui-color-gray10 {
        &.uui-fill-solid {
            --uui-badge-bg-hover: var(--uui-neutral-20);
            --uui-badge-border-hover: var(--uui-neutral-20);
        }

        &.uui-fill-outline {
            --uui-badge-bg: var(--uui-neutral-10);
            --uui-badge-bg-hover: var(--uui-neutral-20);
            --uui-badge-border: var(--uui-neutral-30);
            --uui-badge-border-hover: var(--uui-neutral-30);
            --uui-badge-caption: var(--uui-neutral-70);
            --uui-badge-caption-hover: var(--uui-neutral-70);

            .uui-count_indicator {
                --uui-count_indicator-bg: color-mix(in srgb, var(--uui-badge-caption) 8%, transparent);
                --uui-count_indicator-border: color-mix(in srgb, var(--uui-badge-caption) 40%, transparent);
            }
        }
    }

    .uui-badge.uui-color-gray60 {
        &.uui-fill-outline {
            --uui-badge-bg-hover: var(--uui-neutral-30);
            --uui-badge-border: var(--uui-neutral-60);
            --uui-badge-border-hover: var(--uui-neutral-60);
            --uui-badge-caption: var(--uui-neutral-80);
            --uui-badge-caption-hover: var(--uui-neutral-80);
        }
    }

    .uui-badge.uui-color-yellow.uui-fill-outline {
        --uui-badge-border: var(--yellow-20);
    }

    /* Button */

    .uui-button:is(.uui-color-secondary, .uui-color-neutral, .uui-color-gray, .uui-color-gray50, .uui-color-gray60):is(.uui-fill-outline, .uui-fill-none, .uui-fill-ghost) {
        --uui-btn-caption: var(--uui-neutral-70);
    }

    .uui-button {
        &.uui-size-42, &.uui-size-48 {
            .uui-caption {
                font-family: var(--font-museo-sans);
            }
        }
    }

    /* IconButton */

    .uui-icon_button.uui-color-neutral,
    .uui-icon_button.uui-color-gray60 {
        --uui-icon_btn: var(--uui-neutral-60);
        --uui-icon_btn-hover: var(--uui-neutral-70);
        --uui-icon_btn-active: var(--uui-neutral-80);
    }

    .uui-icon_button.uui-color-secondary {
        --uui-icon_btn: var(--uui-neutral-50);
        --uui-icon_btn-hover: var(--uui-neutral-60);
        --uui-icon_btn-active: var(--uui-neutral-70);
    }

    .uui-icon_button.uui-color-white {
        --uui-icon_btn-hover: var(--uui-neutral-20);
        --uui-icon_btn-active: var(--uui-neutral-30);
    }

    /* LinkButton */

    .uui-link_button.uui-color-white {
        --uui-link_btn-text-hover: var(--uui-neutral-30);
        --uui-link_btn-text-active: var(--uui-neutral-40);
        --uui-link_btn-text-disabled: color-mix(in srgb, var(--white) 40%, transparent);
    }

    /* Tooltip */

    .uui-tooltip-container.uui-color-white {
        --uui-tooltip-bg: var(--uui-neutral-0);
        --uui-tooltip-text: var(--uui-neutral-80);
    }

    .uui-tooltip-container.uui-color-red {
        --uui-tooltip-bg: var(--uui-critical-60);
    }

    .uui-tooltip-container.uui-color-gray {
        --uui-tooltip-bg: var(--uui-neutral-80);
        --uui-tooltip-text: var(--uui-neutral-5);
    }

    /* CountIndicator */

    .uui-count_indicator.uui-color-gray,
    .uui-count_indicator.uui-color-neutral {
        --uui-count_indicator-bg: var(--uui-neutral-30);
        --uui-count_indicator-border: var(--uui-neutral-30);
    }

    .uui-count_indicator:is(.uui-color-gray, .uui-color-neutral, .uui-color-white, .uui-color-amber, .uui-color-warning) {
        --uui-count_indicator-caption: var(--uui-neutral-70);
    }

    /* Status Indicator */

    .uui-status_indicator.uui-color-white.uui-fill-outline {
        .uui-status_indicator-dot {
            background-color: color-mix(in srgb, var(--white) 20%, transparent);
            border: 1px solid var(--uui-neutral-40);
        }
    }

    /* FlexRow */

    .uui-flex-row.uui-color-none {
        --uui-flex-row-bg: transparent;
    }

    .uui-flex-row.uui-color-white {
        --uui-flex-row-bg: var(--white);
    }

    .uui-flex-row.uui-color-gray5 {
        --uui-flex-row-bg: var(--gray5);
    }

    .uui-panel.uui-color-white {
        --uui-panel-bg: var(--white);
    }

    .uui-panel.uui-color-gray90 {
        --uui-panel-bg: var(--gray90);
    }

    /* Text */

    .uui-text.uui-color-gray5 {
        --uui-text: var(--gray5);
    }

    .uui-text.uui-color-gray80 {
        --uui-text: var(--gray80);
    }

    .uui-text.uui-color-gray90 {
        --uui-text: var(--gray90);
    }

    .uui-text.uui-color-blue {
        --uui-text: var(--blue-70);
    }

    .uui-text.uui-color-green {
        --uui-text: var(--green-70);
    }

    .uui-text.uui-color-amber {
        --uui-text: var(--amber-70);
    }

    .uui-text.uui-color-red {
        --uui-text: var(--red-70);
    }

    /* Tag */

    .uui-tag {
        --uui-border-radius: 3px;

        &.uui-color-white {
            &.uui-fill-solid {
                --uui-tag-bg-hover: var(--uui-neutral-10);
                --uui-tag-border-hover: var(--uui-neutral-10);

                .uui-count_indicator {
                    --uui-count_indicator-bg: var(--uui-neutral-30);
                    --uui-count_indicator-border: var(--uui-neutral-30);
                }
            }

            &.uui-fill-outline {
                --uui-tag-bg: var(--uui-neutral-0);
                --uui-tag-border: var(--uui-neutral-30);
                --uui-tag-bg-hover: var(--uui-neutral-10);
                --uui-tag-border-hover: var(--uui-neutral-30);

                .uui-count_indicator {
                    --uui-count_indicator-bg: var(--uui-neutral-30);
                    --uui-count_indicator-border: var(--uui-neutral-30);
                }
            }
        }

        &.uui-color-gray10 {
            &.uui-fill-solid {
                --uui-tag-bg-hover: var(--uui-neutral-20);
                --uui-tag-border-hover: var(--uui-neutral-20);

                .uui-count_indicator {
                    --uui-count_indicator-bg: var(--uui-neutral-30);
                    --uui-count_indicator-border: var(--uui-neutral-30);
                }
            }

            &.uui-fill-outline {
                --uui-tag-bg: var(--uui-neutral-5);
                --uui-tag-bg-hover: var(--uui-neutral-10);
                --uui-tag-border: var(--uui-neutral-30);
                --uui-tag-border-hover: var(--uui-neutral-30);

                .uui-count_indicator {
                    --uui-count_indicator-bg: var(--uui-neutral-30);
                    --uui-count_indicator-border: var(--uui-neutral-30);
                }
            }
        }

        &.uui-color-neutral,
        &.uui-color-gray30 {
            &.uui-fill-solid {
                --uui-tag-bg: var(--uui-neutral-30);
                --uui-tag-bg-hover: var(--uui-neutral-40);
                --uui-tag-border: var(--uui-neutral-30);
                --uui-tag-border-hover: var(--uui-neutral-40);
                --uui-tag-caption: var(--uui-neutral-70);
                --uui-tag-fill: var(--uui-neutral-70);

                .uui-count_indicator {
                    --uui-count_indicator-bg: var(--uui-neutral-0);
                    --uui-count_indicator-border: var(--uui-neutral-0);
                    --uui-count_indicator-caption: var(--uui-neutral-70);
                }
            }

            &.uui-fill-outline {
                --uui-tag-bg: var(--uui-neutral-20);
                --uui-tag-bg-hover: var(--uui-neutral-30);
                --uui-tag-border: var(--uui-neutral-40);
                --uui-tag-border-hover: var(--uui-neutral-40);

                .uui-count_indicator {
                    --uui-count_indicator-bg: var(--uui-neutral-30);
                    --uui-count_indicator-border: var(--uui-neutral-30);
                }
            }
        }

        &.uui-color-gray70 {
            &.uui-fill-solid {
                --uui-tag-caption: var(--uui-neutral-0);
                --uui-tag-fill: var(--uui-neutral-0);
                --uui-tag-bg: var(--uui-neutral-70);
                --uui-tag-border: var(--uui-neutral-70);
                --uui-tag-bg-hover: var(--uui-neutral-80);
                --uui-tag-border-hover: var(--uui-neutral-80);

                .uui-count_indicator {
                    --uui-count_indicator-border: color-mix(in srgb, var(--uui-neutral-0) 50%, transparent);
                    --uui-count_indicator-bg: color-mix(in srgb, var(--uui-neutral-0) 10%, transparent);
                    --uui-count_indicator-caption: var(--uui-neutral-0);
                }
            }

            &.uui-fill-outline {
                --uui-tag-bg: var(--uui-neutral-20);
                --uui-tag-bg-hover: var(--uui-neutral-30);
                --uui-tag-border: var(--uui-neutral-50);
                --uui-tag-border-hover: var(--uui-neutral-50);

            }
        }

        &.uui-color-warning,
        &.uui-color-amber {
            &.uui-fill-solid {
                --uui-tag-caption: var(--uui-text-primary);
                --uui-tag-fill: var(--uui-neutral-90);

                .uui-count_indicator {
                    --uui-count_indicator-bg: color-mix(in srgb, var(--uui-neutral-90) 5%, transparent);
                    --uui-count_indicator-border: color-mix(in srgb, var(--uui-neutral-90) 30%, transparent);
                }
            }

            &.uui-fill-outline .uui-count_indicator {
                --uui-count_indicator-bg: var(--uui-neutral-0);
                --uui-count_indicator-border: var(--uui-neutral-40);
            }
        }
    }

    .uui-dropdown-body {
        --uui-dropdown_container-border-radius: 0;
    }

    .uui-link_button {
        &.uui-size-42, &.uui-size-48 {
            :global(.uui-caption) {
                font-family: var(--font-museo-sans);
                text-transform: uppercase;
            }
        }
    }

    /* fonts */

    .uui-font-sans {
        --uui-text-font: var(--uui-font);
        --uui-text-font-weight: 400;
    }

    .uui-font-sans-semibold {
        --uui-text-font: var(--uui-font);
        --uui-text-font-weight: 600;
    }

    .uui-font-sans-italic {
        --uui-text-font: var(--uui-font);
        --uui-text-font-weight: 400;
        --uui-text-font-style: italic;
    }

    .uui-font-museo-sans {
        --uui-text-font: var(--font-museo-sans);
        --uui-text-font-weight: normal;
    }

    .uui-font-museo-slab {
        --uui-text-font: var(--font-museo-slab);
        --uui-text-font-weight: normal;
    }

    /* Typography */

    .uui-typography {
        h1, h2, h3 {
            font-family: var(--font-museo-sans);
        }

        h1 {
            font-size: 36px;
        }

        .hero-header {
            font-family: var(--font-museo-slab);
        }

        .promo-header:is(h1, h2, h3) {
            font-family: var(--font-museo-sans);
        }
    }
}


