.group-title {
    margin-bottom: 0;
}

.group-info {
    margin-top: 6px;
}

.subgroup {
    padding: 0 36px;

    .subgroup-title {
        margin-top: 32px;
    }

    .token-card {
        padding-top: 18px;
        padding-bottom: 18px;

        .var {
            cursor: pointer;
            padding: 3px 6px;
        }

        &:last-of-type {
            border-bottom-style: none;
        }

        .token-card-info {
            &:nth-of-type(3) {
                padding-top: 0;
            }
        }
    }

    .color-viewer {
        box-sizing: border-box;
        width: 106px;
        height: 36px;
        border: 1px solid var(--uui-control-border);
        border-radius: 3px;
        text-align: center;
        margin-top: 0;
    }
}

.hide-button {
    width: 128px;
}

.var {
    max-width: fit-content;
    font-family: var(--uui-font-mono);
    background-color: var(--uui-surface-higher);
    padding: 3px 4px;
    border-radius: 3px;

    &:hover {
        background-color: var(--uui-surface-highest);
    }
}

.semantic-table-header {
    position: sticky;
    z-index: 1000;
    top: 0;
    background: var(--uui-surface-main);
    display: grid;
    grid-template-columns: 2fr repeat(6, 1fr);
    align-items: center;
    column-gap: 12px;
    padding-top: 18px;
    padding-bottom: 9px;
}

.table-header {
    text-align: center;
}

.with-border {
    border-bottom: 1px solid var(--uui-control-border);
}

.no-paddings {
    padding: 0;
}

.small-paddings {
    padding: 3px 0;
}

.header-description {
    white-space: break-spaces;
    padding: 0 0 3px;
}

.semantic-table-rows {
    display: grid;
    grid-template-columns: 220px repeat(6, 1fr);
    align-items: center;
    column-gap: 12px;
    row-gap: 21px;
    text-overflow: ellipsis;
}

.semantic-title-cell {
    align-self: flex-start;
}

.semantic-title-row {
    min-height: auto;
}

.color-viewer {
    box-sizing: border-box;
    width: 100%;
    height: 36px;
    border-radius: 3px;
    text-align: center;
    margin-top: 6px;
    margin-bottom: 6px;

    &.bordered {
        border: 1px solid var(--uui-control-border);
    }
}

.semantic-items-wrapper {
    cursor: pointer;
}

.semantic-item {
    height: 18px;
    padding-top: 0;
    padding-bottom: 0;
    opacity: 1;
    transition: 0.3s ease-in-out;

    &:nth-of-type(2) {
        margin: 6px 0 0;
    }
}

.tooltip-semantic-item {
    padding: 6px 0;
}

.hidden-item {
    height: 0;
    transform: rotate3d(90, 0, 0, 90deg);
    opacity: 0;
    visibility: hidden;
}


.semantic-tooltip-block {
    margin-top: 9px;
    display: grid;
    grid-template-columns: 86px max-content;
    column-gap: 9px;

    :global(.uui-text) {
        padding: 3px 0;
    }
}

.semantic-tooltip-title {
    margin: 0;

    span {
        color: var(--uui-text-tertiary);
    }
}

.semantic-tooltip-block-middle {
    display: grid;
    grid-template-columns: 86px max-content;
    column-gap: 9px;

    :global(.uui-text) {
        padding: 3px 0 9px;
    }
}
