@use '~@epam/promo/assets/styles/colors' as *;
@use  '~@epam/uui/assets/styles/dnd' as *;
@use './mixins.module' as *;

.drag-element {
    position: relative;
    padding: 6px 0;
    margin: 0 24px;

    @include dnd-cursor-style();

    .dnd-item {
        border: 1px solid var(--uui-divider);
    }

    &:hover .drag-handle {
        visibility: visible;
    }

    .icon-wrapper {
        padding: 12px 24px;

        .icon {
            fill: var(--uui-icon);
        }

        &:first-child {
            border-right: 1px solid var(--uui-divider);
        }
    }

    .text-wrapper {
        padding-left: 6px;
    }

    &:global(.uui-dragged-out) {
        @include drag-out;
    }

    :global(.uui-drag-ghost) {
        box-shadow: 0 6px 18px 0 rgba(29, 30, 38, 0.05), 0 3px 12px 0 rgba(29, 30, 38, 0.05);
        @include visibility;
    }
}

@include drag-handle(8px, 12px, 12px, 130%);
