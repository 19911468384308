@import '~@epam/promo/assets/styles/index';

.title {
    font-family: var(--uui-font);
    font-weight: 600;
    margin: 24px 0;
    font-size: 36px;
    line-height: 48px;
}

.header-wrapper {
    h2 {
        margin-top: 30px;
        margin-bottom: 0;
    }
}

.team-layout {
    flex-wrap: wrap;
    margin-right: -24px;

    .card {
        margin-right: 24px;
        margin-top: 24px;
        line-height: 1px;

        :global(.uui-caption) {
            font-size: 16px;
            line-height: 24px;
            margin-top: 12px;
            padding: 0;
        }
    }

    .contribute-card {
        border: 1px solid var(--uui-info-50);
        box-sizing: border-box;
        margin-top: 24px;
        min-width: 222px;

        .icon-container {
            min-height: 220px;
            align-items: center;
            border-bottom: 1px solid var(--uui-info-50);
        }

        .link {
            justify-content: center;

            :global(.uui-caption) {
                font-size: 16px;
                padding: 17px 0;
            }
        }
    }
}

.icon-blue {
    fill: var(--uui-primary-50);
}
