@use '~@epam/promo/assets/styles/index' as *;
@use '../contrast' as *;

.title {
    font-family: $font-museo-sans;
    color: $gray80;
    font-size: 30px;
    margin-bottom: 6px;
}

.hex-text, .color-name {
    font-size: 14px;
}

.hex-text {
    cursor: pointer;
    border: 0 none;
    background-color: transparent;
    color: inherit;

    &:hover {
        opacity: 0.75;
    }
}

.box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.basic-color-box {
    width: 120px;
    height: 60px;
    align-items: center;
}

.grayscale-color-box {
    width: 96px;
    height: 60px;
    align-items: center;
}

.additional-color-box {
    width: 120px;
    height: 60px;
    align-items: center;
}

.caption-color-box {
    width: 96px;
    height: 60px;
    padding-left: 12px;
}

.title {
    font-family: $font-museo-sans;
    margin: 24px 0;
    font-size: 36px;
    line-height: 48px;
}
