@use '~@epam/assets/theme/theme_promo' as *;
@use '~@epam/promo/assets/styles/colors.scss' as *;
@use '~@epam/promo/assets/styles/fonts' as *;

.root {
    width: 240px;
    background-color: var(--uui-surface-main);
    display: flex;
    flex-direction: column;

    .tree {
        overflow: auto;
        border: 1px solid var(--uui-divider);
        border-top: 0;
        border-bottom: 0;
        flex-grow: 1;
        flex-shrink: 0;
        flex-basis: 0;
    }
}

.search {
    --uui-text_input-border-radius: 0;
    padding: 0 12px;
}
