@use '~@epam/promo/assets/styles/index' as *;

.title {
    font-family: var(--uui-font);
    font-weight: 600;
    margin: 24px 0;
    font-size: 36px;
    line-height: 48px;
}

.layout {
    .release-row {
        padding-top: 23px;
    }

    .release-header {
        padding: 0;
    }

    .release-date {
        padding: 0;
    }

    .release-content {
        max-width: 714px;
        width: 100%;
        border-bottom: 1px solid var(--uui-divider);
        padding-bottom: 24px;
    }

    p {
        margin-top: 0;

        &:not(:first-child) {
            margin-top: 18px;
        }

        b {
            font-size: 16px;
            line-height: 24px;
        }
    }

    ul {
        list-style-type: disc;
        margin: 0 0 0 6px;

        li {
            font-size: 16px;
            line-height: 24px;
            padding-left: 12px;
        }
    }
}
