@use './mixins.module' as *;
@use  '~@epam/uui/assets/styles/dnd' as *;

.drag-element {
    position: relative;
    margin: 1px 0;
    @include dnd-cursor-style();

    &:hover .drag-handle {
        visibility: visible;
    }

    &:global(.uui-dragged-out) {
        @include drag-out;
    }

    &:global(.uui-drag-ghost) {
        box-shadow: 0 6px 18px 0 rgba(29, 30, 38, 0.05), 0 3px 12px 0 rgba(29, 30, 38, 0.05);
        @include visibility;
    }

    .item {
        background-color: var(--uui-surface-main);
    }

    .module-icon {
        width: 36px;
        height: 36px;
        padding: 6px;
        border-radius: 50%;
        box-sizing: border-box;
        align-self: center;

        &.complete-icon {
            background-color: #EEFFCC;
        }

        &.schedule-icon {
            background-color: #F5F6FA;
        }
    }
}

@include drag-handle(5px, 9px, 9px, 125%);

.icon-green {
    fill: var(--uui-success-50);
}

.icon-gray50 {
    fill: var(--uui-secondary-50);
}
