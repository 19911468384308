.slide {
    display: flex;

    .card {
        img {
            padding-bottom: 8px;
            user-select: none;
        }
    }

    .testimonial-text {
        margin-left: 24px;
        padding: 0;
        line-height: 36px;
    }

    .quote-text {
        font-size: 30px;
        line-height: 36px;
        min-width: 282px;
        margin-left: 48px;
        padding: 0;
    }

    @media (max-width: 1280px) {
        .testimonial-text {
            font-size: 22px;
            line-height: 30px;
        }
    }
}
