.link:hover {
  cursor: pointer;
}

.input-wrapper {
  padding: 9px 24px;
}

.footer {
  justify-content: flex-end;
}
@media (max-width: 720px) {
  .footer :global(.uui-button) {
    flex-basis: 50%;
  }
}