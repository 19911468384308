.panel {
  max-width: 600px;
}

.button-group {
  display: flex;
  justify-content: flex-end;
}
.button-group > * {
  margin: 10px;
}

.input-group {
  display: flex;
  justify-content: space-between;
}