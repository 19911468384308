.task-row {
    position: relative;
    top: 0;
    overflow: hidden;
    height: 100%;
    width: 100%;
    user-select: none;
    border-inline-end: 1px solid var(--uui-dt-border);
    background-color: var(--uui-dt-cell-bg);

}

.layer {
    position: absolute;
    top: 0;
    inset-inline-start: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
