@use '~@epam/promo/assets/styles/colors' as *;

@keyframes show-left-filter-panel {
    0% {
        transform: translate3d(-100%, 0, 0);
        margin-right: var(--sliding-panel-margin);
    }

    100% {
        transform: translate3d(0, 0, 0);
        margin-right: 0;
    }
}

@keyframes hide-left-filter-panel {
    0% {
        transform: translate3d(0, 0, 0);
        margin-right: 0;
    }

    100% {
        transform: translate3d(-100%, 0, 0);
        margin-right: var(--sliding-panel-margin);
    }
}

@keyframes show-right-filter-panel {
    0% {
        transform: translate3d(100%, 0, 0);
        margin-left: var(--sliding-panel-margin);
    }

    100% {
        transform: translate3d(0, 0, 0);
        margin-left: 0;
    }
}

@keyframes hide-right-filter-panel {
    0% {
        transform: translate3d(0, 0, 0);
        margin-left: 0;
    }

    100% {
        transform: translate3d(100%, 0, 0);
        margin-left: var(--sliding-panel-margin);
    }
}

body {
    &:global(.full-screen) {
        .container {
            height: 100vh;
        }
    }
}

.container {
    overflow: hidden;
    height: calc(100vh - 102px);
    border-right: 1px solid var(--uui-divider);
    flex-shrink: 0;

    &-left {
        transform: translate3d(-100%, 0, 0);
        margin-right: var(--sliding-panel-margin);

        &-hide {
            animation: hide-left-filter-panel 500ms;
            animation-fill-mode: forwards;
        }

        &-show {
            animation: show-left-filter-panel 500ms;
            animation-fill-mode: forwards;
        }
    }

    &-right {
        transform: translate3d(100%, 0, 0);
        margin-left: var(--sliding-panel-margin);

        &-hide {
            animation: hide-right-filter-panel 500ms;
            animation-fill-mode: forwards;
        }

        &-show {
            animation: show-right-filter-panel 500ms;
            animation-fill-mode: forwards;
        }
    }
}
