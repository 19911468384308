.row {
  margin: 18px 0;
  align-items: stretch;
  border: 1px solid var(--uui-control-border);
  overflow: hidden;
  background: var(--uui-surface-main);
}
.row .input {
  padding: 0;
  border-color: transparent;
  margin-left: -7px;
}
.row .input :global(.uui-input) {
  font-family: var(--uui-font);
  font-weight: 600;
  font-size: 16px;
}
.row .input:hover {
  border-color: var(--uui-control-border);
}
.row .input:global(.uui-focus) {
  border-color: var(--uui-primary-50);
}
.row:global(.uui-focus) {
  border-color: var(--uui-primary-50);
}

.imgBox {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--uui-surface-main);
  fill: var(--uui-icon);
}
.imgBox:first-of-type {
  border-right: 1px solid var(--uui-control-border);
}
.imgBox .doc-color {
  fill: #2B579A;
}
.imgBox .xls-color {
  fill: #217346;
}
.imgBox .pdf-color {
  fill: #FA0F01;
}

.linkWrapper {
  padding: 0 24px 0 12px;
  width: 100%;
  height: 100%;
  justify-content: center;
}
.linkWrapper svg {
  width: 27px;
  height: 27px;
  fill: var(--uui-icon);
}
.linkWrapper:hover svg {
  fill: var(--uui-icon-hover);
}

.info {
  padding: 12px;
}

.link {
  padding: 3px 0 3px 6px;
  display: block;
  font-family: var(--uui-font);
  line-height: 18px;
  font-size: 12px;
  color: var(--uui-link);
}

.size-label {
  color: var(--uui-text-tertiary);
  line-height: 18px;
  font-size: 12px;
  font-family: var(--uui-font);
}

.file-name {
  font-family: var(--uui-font);
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: var(--uui-text-primary);
  overflow: hidden;
  text-overflow: ellipsis;
}