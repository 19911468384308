.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 6px 0;
    font-size: 14px;
}

.icon {
    fill: var(--uui-icon);
}

.icon {
    fill: var(--uui-icon);
}
