.root {
    height: calc(100vh - 102px);
    background: white;
}

body {
    &:global(.full-screen) {
        .root {
            height: 100vh;
        }
    }
}
