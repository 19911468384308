@use '~@epam/assets/theme/theme_promo' as *;

.container {
    overflow: hidden;
    display: flex;
    position: relative;
}

.apply {
    width: 200px;
    margin-left: auto;
    margin-right: auto;
}


.wrapper {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 96px);
    transition: all 500ms;
    flex-basis: auto;
    min-width: 0;
    flex-grow: 1;

    :global(.uui-table-row) {
        .detailed-icon {
            visibility: hidden;
        }

        &:hover {
            .detailed-icon {
                visibility: visible;
            }
        }
    }
}