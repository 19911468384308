.root {
    &:global(.uui-button-box) {
        min-height: 36px;
        height: auto;
        align-items: center;
        padding-inline-start: 11px;
        font-size: 16px;
        width: 100%;
        min-width: auto;
        padding-inline-end: 12px;
    }

    :global(.uui-caption) {
        min-width: 0;
        text-align: start;
        flex: 1 1 auto;
        font-family: var(--uui-font);
        font-weight: 400;
        user-select: none;
        font-size: 16px;
        overflow-x: hidden;
        text-overflow: ellipsis;
        white-space: break-spaces;
        height: auto;
    }

    &.indent-12 {
        :global(.uui-caption) {
            margin-inline-start: 12px;
        }
    }

    &.indent-24 {
        :global(.uui-caption) {
            margin-inline-start: 24px;
        }
    }
}
