/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 400" instead "$font-sans"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 600" instead "$font-sans-semibold"!
 */
/**
 * @deprecated Please use "$font-source-sans" us "font-weight: 400" & "font-style: italic" instead "$font-sans-italic"!
 */
.container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
}
.container .color-item {
  width: 12px;
  height: 12px;
  cursor: pointer;
  border-style: solid;
  border-width: 3px;
  border-radius: 50%;
}
.container .color-item-selected {
  border-width: 2px;
  width: 14px;
  height: 14px;
  border-color: white;
}
.container .color-item-unknown {
  word-break: break-word;
  min-width: 15px;
  margin: 0 2px 0 0;
}