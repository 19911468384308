.image {
  --radius: 0.5rem;
  display: block;
  width: 100%;
  height: auto;
  padding-left: 0;
  padding-right: 0;
  cursor: pointer;
  object-fit: cover;
  border-radius: calc(var(--radius) - 4px);
}

.imageCaption {
  max-width: 100%;
}

.selected-image {
  box-shadow: var(--uui-shadow-level-2);
}

.caption {
  margin-top: 0.5rem;
  width: 100%;
  resize: none;
  border-style: none;
  background-color: inherit;
  padding: 0;
  font-family: inherit;
  color: var(--uui-text-primary);
  text-align: center;
}

.caption:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}
.caption:focus::placeholder {
  opacity: 0;
}

.align-center {
  margin-left: auto;
  margin-right: auto;
}

.align-left {
  margin-right: auto;
}

.align-right {
  margin-left: auto;
}

.group {
  position: relative;
}

.resize-handle-opacity::after {
  opacity: 0;
}

.group:hover .resize-handle-opacity::after {
  opacity: 1;
}

.resize-handle-visible::after {
  opacity: 1;
}