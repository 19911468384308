.wrapper {
    flex-grow: 1;
    margin: 24px;
}

.icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 20px;
    border-radius: 2px;
}
