
.container {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    height: calc(100vh - 60px);
    width: 100%;
    overflow: hidden;
}

.sticky-footer {
    position: sticky;
    bottom: 0;
    min-width: min-content;
    z-index: 3;
    border-top: 1px solid var(--night300);

    & > * {
        background-color: var(--night50) !important;
    }
}
