.container {
  outline: none;
  width: 100%;
  height: 100%;
  border: 1px solid transparent;
  position: relative;
}
.container:global(.uui-focus) {
  border-color: var(--uui-primary-50);
}

.slate-image > div:last-of-type:not(:only-of-type) {
  display: none;
}

.container-hover:hover {
  border: 1px solid var(--uui-primary-50);
}

.image-toolbar {
  background-color: var(--uui-neutral-80);
  border: 1px solid var(--uui-divider-light);
  display: flex;
}

.image-toolbar-wrapper {
  margin-bottom: 6px;
}

.all-width {
  width: 100%;
}

.wrapper {
  margin: 18px 0;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.wrapper figcaption {
  max-width: 100%;
}

.dot {
  width: 12px;
  height: 12px;
  background: var(--uui-primary-50);
  visibility: hidden;
}

.container-wrapper:hover .dot {
  visibility: visible;
}

.left-top-dot {
  position: absolute;
  top: -6px;
  left: -6px;
}

.right-top-dot {
  position: absolute;
  top: -6px;
  right: -6px;
}

.left-bot-dot {
  position: absolute;
  bottom: -6px;
  left: -6px;
}

.right-bot-dot {
  position: absolute;
  bottom: -6px;
  right: -6px;
}

.align-right {
  align-items: flex-end;
}

.align-center {
  align-items: center;
}

.align-left {
  align-items: flex-start;
}