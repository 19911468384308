@use './mixins.module' as *;
@use  '~@epam/uui/assets/styles/dnd' as *;

.drag-element {
    position: relative;
    width: max-content;
    margin: 1px 6px;
    @include dnd-cursor-style();

    .row {
        min-height: 18px;
        background-color: var(--uui-surface-main);
    }

    &:hover .drag-handle {
        visibility: visible;
    }

    &:global(.uui-dragged-out) {
        @include drag-out;
    }

    &:global(.uui-drag-ghost) {
        box-shadow: 0 6px 18px 0 rgba(29, 30, 38, 0.05), 0 3px 12px 0 rgba(29, 30, 38, 0.05);
        @include visibility;
    }
}

@include drag-handle(3px, 9px, 9px, 120%);
