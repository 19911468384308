.align-center {
  margin-left: auto;
  margin-right: auto;
}

.align-left {
  margin-right: auto;
}

.align-right {
  margin-left: auto;
}

.resize-handle {
  position: absolute;
  z-index: 40;
}

.left-handle {
  display: flex;
  width: 1.5rem;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  justify-content: center;
  top: 0;
  height: 100%;
  cursor: col-resize;
  margin-left: -0.75rem;
  left: -0.75rem;
  padding-left: 0.75rem;
}

.right-handle {
  display: flex;
  width: 1.5rem;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  justify-content: center;
  top: 0;
  height: 100%;
  cursor: col-resize;
  align-items: flex-end;
  right: -0.75rem;
  margin-right: -0.75rem;
  padding-right: 0.75rem;
}

.top-handle {
  display: flex;
  width: 1.5rem;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  cursor: row-resize;
}

.bottom-handle {
  display: flex;
  width: 1.5rem;
  -webkit-user-select: none;
  user-select: none;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  cursor: row-resize;
}

.left-handle::after {
  content: " ";
  display: flex;
  height: 4rem;
  width: 3px;
  border-radius: 6px;
  background-color: var(--uui-neutral-50);
  opacity: 0;
}

.right-handle::after {
  content: " ";
  display: flex;
  height: 4rem;
  width: 3px;
  border-radius: 6px;
  background-color: var(--uui-neutral-50);
  opacity: 0;
}