.width-wrapper {
    padding: 0 60px 60px;
    max-width: 960px;
}

@media (max-width: 768px) {
    .width-wrapper {
        padding: 0 18px 18px;
    }
}
