@use '~@epam/promo/assets/styles/index' as *;

.container {
    --uui-docs-bg: var(--uui-surface-main);
    margin: 24px 0;
    max-width: 100%;
}

.container-footer-wrapper {
    display: flex;
    background-color: var(--uui-surface-main);

    > .container-footer {
        flex-grow: 1;
    }

    > a {
        display: flex;
        align-items: center;
        padding: 12px;
    }
}

.container-footer {
    justify-content: space-between;
    background-color: var(--uui-surface-main);
}

.preview-container {
    background: var(--uui-docs-bg);
    border: 1px solid var(--uui-divider);
    width: 960px;
    max-width: 100%;
}
