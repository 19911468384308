.sticky-header {
    position: sticky;
    top: 0;
    isolation: isolate;
    z-index: 5;
}

.radioGroupFilter {
    height: 120px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 12px;
}

.blocker {
    :global(.uui-blocker) {
        z-index: 10;
    }

    :global(.uui-spinner-container) {
        transition-duration: 0s;
        transition-delay: 0s;
    }
}
