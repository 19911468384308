@use '~@epam/promo/assets/styles/index' as *;

.root {
    background-color: var(--uui-app-bg);
    display: flex;
    flex-direction: column;
    height: calc(100vh - 102px);
    overflow: auto;

    .nav-page {
        margin: 0 auto 30px auto;
        max-width: 1200px;

        .nav-title {
            font-family: var(--uui-font);
            font-weight: 600;
            color: var(--uui-text-primary);
            margin: 30px 0 5px;
            font-size: 36px;
            line-height: 48px;
        }

        .nav-cards {
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-wrap: wrap;
        }

        @media (max-width: 1280px) {
            width: fit-content;

            .nav-cards {
                flex-direction: column;
                align-items: center;
            }
        }
    }

    .secondary-navigation {
        min-height: 48px;
        padding: 0 18px;

        .source-link {
            padding: 0 12px 0 6px;
        }
    }
}




