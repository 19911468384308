.root {
    display: flex;
    flex: 1 1 auto;
}

.code {
    font-family: monospace;
    font-size: 14px;
    line-height: 24px;
    color: var(--uui-text-primary);
    padding: 12px 24px;
    white-space: pre-wrap;
    overflow: hidden;
}

.demo-context {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    min-width: 0;
    align-items: flex-start;

    .context-setting-row {
        justify-content: center;
        box-sizing: border-box;
        width: 100%;
    }
}

.demo-container {
    overflow: auto;
    flex-grow: 1;
    flex-shrink: 0;
    flex-basis: 0;
    width: 100%;
    background-color: var(--uui-surface-main);
}

.not-support {
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .back-button {
        padding-top: 0;

        :global(.uui-caption) {
            font-size: 16px;
        }
    }
}
