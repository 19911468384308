@use '~@epam/loveship/assets/styles/scss/index' as *;
@use '../contrast' as *;

.hex-text, .color-name {
    font-size: 14px;
}

.contrast-text {
    color: var(--uui-contrast-color-700);
}

.hex-text {
    cursor: pointer;
    border: 0 none;
    background-color: transparent;
    color: inherit;

    &:hover {
        opacity: 0.75;
    }
}

.box {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.basic-color-box {
    width: 120px;
    height: 60px;
    align-items: center;
}

.grayscale-color-box {
    width: 96px;
    height: 60px;
    align-items: center;
}

.additional-color-box {
    width: 120px;
    height: 60px;
    align-items: center;
}

.caption-color-box {
    width: 96px;
    height: 60px;
    padding-left: 12px;
}
