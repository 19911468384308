/* Main Colors */
/* Gray */
.list {
  max-height: 300px;
  border: 1px solid #EBEDF5;
}

.row {
  min-width: 250px;
}

.panel {
  width: 300px;
}

.text {
  white-space: pre-wrap;
}

.pickerRow {
  --uui-picker_input-selected_mark: var(--uui-info-50);
  height: 38px;
}