.checkbox-container {
  padding-right: 6px;
}

.item-container {
  display: flex;
}

.text-container {
  flex-grow: 1;
}