.container .selectContainer {
  display: flex;
  align-items: center;
}
.container .infoContainer {
  margin-left: 12px;
  display: flex;
  align-items: center;
}
.container .infoContainer svg {
  width: 18px;
  height: 18px;
}
.container .infoText {
  margin-right: 4px;
}

.contentTooltip {
  padding: 12px 6px;
}

.item {
  display: flex;
}
.item .itemIcon {
  margin: 5px 0;
  width: 30px;
  height: 30px;
  align-items: center;
  border: 1px solid var(--uui-divider);
  border-radius: 3px;
}
.item .itemIcon svg {
  fill: var(--uui-icon);
}
.item .customSize svg {
  width: 18px;
  height: 18px;
}
.item .itemName {
  margin-top: 6px;
}
.item .itemText {
  padding-left: 12px;
  flex: 1 1 auto;
  cursor: text;
}

.toggler svg {
  height: 12px;
  width: inherit;
}