.root {
    display: flex;
    justify-content: center;
    flex-shrink: 0;
    height: 240px;
    padding: 0 18px;

    .sponsor-text {
        margin-top: 24px;
    }

    .container {
        display: flex;
        flex-direction: row;
        flex-basis: 1166px;
        padding: 36px 0;
    }

    .first-block {
        margin-top: 9px;
        width: 50%;
        flex-basis: 50%;

        .bottom-links {
            :global(.uui-text) {
                padding-top: 0;
            }
        }
    }

    .second-block {
        flex-basis: 50%;
        width: 50%;

        .mail-button {
            margin-top: 9px;
            padding-inline-start: 18px;
            padding-inline-end: 18px;
        }
    }

    @media (max-width: 950px) {
        & * {
            max-width: fit-content;
            margin: 0 auto;
        }

        .second-block {
            display: none;
        }

        .sponsor-text {
            margin-top: 60px;
        }
    }
}
