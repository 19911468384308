canvas {
  user-select: none;
}

.timelineHeader {
  position: relative;
  background-color: #FFF;
  border-top: 1px solid #EEE;
}

.timelineScale {
  cursor: pointer;
  cursor: grab;
}
.timelineScaleGrabbing {
  cursor: ew-resize;
  cursor: grabbing;
}

.arrow {
  display: inline-block;
  width: 36px;
  position: absolute;
  text-align: center;
  vertical-align: middle;
  margin: auto;
  color: rgba(102, 102, 102, 0.7);
  cursor: pointer;
  fill: #AEAEAE;
}
.arrow:hover {
  fill: #1A9CB0;
}
.arrowLeft {
  left: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0.7) 0%, rgba(255, 255, 255, 0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#b3ffffff", endColorstr="#00ffffff", GradientType=1); /* IE6-9 */
}
.arrowRight {
  right: 0;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.7) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffffff", endColorstr="#b3ffffff", GradientType=1); /* IE6-9 */
}
.arrowIcon {
  height: 12px;
}