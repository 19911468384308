.root {
    margin: 30px auto;
    max-width: 1200px;

    .module-rows-container {
        padding-top: 6px;
        padding-bottom: 6px;
        display: flex;
        flex-direction: column;
    }

    .module-header {
        font-size: 36px;
        line-height: 48px;
        margin-bottom: 12px;
    }

    .module-content {
        position: relative;
    }

    @media (min-width: 1280px) {
        display: flex;
        flex: 0 0 auto;
        align-items: flex-start;
    }

    @media (max-width: 1279px) {
        justify-content: center;
        margin: 30px 24px;
    }
}
