.table-cell-wrapper {
  position: relative;
  overflow: visible;
  border-style: none;
  background-color: var(--uui-surface-main);
  padding: 0;
}

.table-cell-wrapper::before {
  content: "";
  height: 100%;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
}

.border-bottom::before {
  border-bottom: 1px solid var(--uui-divider-light);
}

.border-right::before {
  border-right: 1px solid var(--uui-divider-light);
}

.border-left::before {
  border-left: 1px solid var(--uui-divider-light);
}

.border-top::before {
  border-top: 1px solid var(--uui-divider-light);
}

.selected::before {
  z-index: 10;
  background-color: var(--uui-info-5);
}

.hide-border::before {
  border-style: none;
}

.text-left {
  text-align: left;
}

.text-left > * {
  margin: 0;
}

.cell {
  position: relative;
  z-index: 20;
  box-sizing: border-box;
  height: 100%;
  padding: 6px 24px;
}

.resizable-wrapper {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  -webkit-user-select: none;
  user-select: none;
}

.resize-handle-right {
  background-color: var(--uui-primary-50);
  width: 0.25rem;
  height: 100%;
  z-index: 30;
  right: -1.5px;
  top: 0;
  position: absolute;
}

.resize-handle-left {
  position: absolute;
  left: -1.5px;
  z-index: 30;
  height: 100%;
  top: 0;
  width: 0.25rem;
  background-color: var(--uui-primary-50);
}

.header-cell {
  background-color: var(--uui-surface-higher);
}

.resize-holder-right {
  top: -0.75rem;
  right: -5px;
  width: 10px;
}

.resize-holder-bottom {
  bottom: -5px;
  height: 10px;
}

.resize-holder-left {
  top: -0.75rem;
  left: -5px;
  width: 10px;
}