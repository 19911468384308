/* Main Colors */
/* Gray */
.content {
  margin: 18px 0;
  display: block;
  width: 100%;
  height: 400px;
  border: 1px solid var(--uui-divider);
  box-sizing: border-box;
}
.content:global(.uui-focus) {
  border-color: var(--uui-primary-50);
}

.pdf-button {
  margin-top: 6px;
}
.pdf-button :global(.uui-icon) svg {
  height: 24px !important;
  max-width: 24px !important;
}